<script setup>
import { computed } from 'vue';

const props = defineProps({ blok: Object });

const createHtml = (blok) => {
  const element = document.createElement(blok.hierarchy);

  element.classList += blok.size + ' ';
  element.classList += blok.weight + ' ';
  element.style = blok.style;

  if (blok.font) {
    element.classList += blok.font + ' ';
  }

  if (blok.color) {
    element.classList += blok.color + ' ';
  }

  if (blok.case) {
    element.classList += blok.case + ' ';
  }

  element.innerHTML = blok.text;

  return element.outerHTML;
};

const generatedHtml = computed(() => {
  return createHtml(props.blok);
});
</script>

<template>
  <div style="display: inline-block;" v-html="generatedHtml"></div>
</template>

<style scoped>

</style>
