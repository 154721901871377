import StoryblokClient from 'storyblok-js-client';

export default new class Storyblok {
    constructor() {
        this.client = new StoryblokClient({
            accessToken: 'patgcT3YajYK9C1aaBkJ5Qtt', // TODO: Replace with environment variable
            cache: {
                clear: 'auto',
                type: 'memory'
            }
        });
    }

    async storyblokApiRequest(url, params) {
        try {
            let opts = { version: 'draft' }

            if (params) {
                Object.assign(opts, params)
            }

            const response = await this.client.get(url, opts);
            return response.data;
        } catch (error) {
            console.error('Error in storyblokApiRequest:', error);
            throw error;
        }
    }
}
