<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faHouse, faRectangleHistory, faUsers, faEnvelope } from '@fortawesome/pro-light-svg-icons';

const isNavbarVisible = ref(true);

let lastScrollPosition = 0;

const handleScroll = () => {
  const currentScrollPosition = window.scrollY;

  if (currentScrollPosition === 0) {
    isNavbarVisible.value = true;
  } else isNavbarVisible.value = currentScrollPosition <= lastScrollPosition;

  lastScrollPosition = currentScrollPosition;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <nav :class="{ hidden: !isNavbarVisible }">
    <div class="container full-width full-height grid">
      <a href="/" class="flex flex-direction-column justify-content-center align-items-center">
        <FontAwesomeIcon :icon="faHouse"/>
        <span class="uppercase weight-regular font-secondary">Agentur</span>
      </a>

      <a href="/projekte" class="flex flex-direction-column justify-content-center align-items-center">
        <FontAwesomeIcon :icon="faRectangleHistory" />
        <span class="uppercase weight-regular font-secondary">Projekte</span>
      </a>

      <a href="/jobs" class="flex flex-direction-column justify-content-center align-items-center">
        <FontAwesomeIcon :icon="faUsers" />
        <span class="uppercase weight-regular font-secondary">Jobs</span>
      </a>

      <a href="/kontakt" class="flex flex-direction-column justify-content-center align-items-center">
        <FontAwesomeIcon :icon="faEnvelope" />
        <span class="uppercase weight-regular font-secondary">Kontakt</span>
      </a>
    </div>
  </nav>
</template>

<style scoped lang="scss">
nav {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 75px;
  z-index: 9990;
  background: $secondary;
  transition: transform 0.3s ease-in-out;

  &.hidden {
    transform: translateY(100%);
  }

  .container {
    grid-template-columns: repeat(4, 1fr);

    a {
      position: relative;
      height: 100%;
      gap: 8px;

      &:after {
        position: absolute;
        content: '';
        height: 60%;
        width: 1px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: rgba($text, .15);
      }

      &:last-child:after {
        width: 0;
      }

      span {
        font-size: 10px;
        color: rgba($text, .75);
      }
    }
  }

  @media only screen and (max-width: #{$media-phone}) {
    display: block;
  }
}
</style>