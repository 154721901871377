<script setup>
import { computed } from 'vue';

defineProps({ blok: Object })

const first_name = computed(() => {
  const params = new URLSearchParams(window.location.search);

  const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
  );

  return paramsObj.for;
});
</script>

<template>
  <h2 class="font-primary font-large weight-bold color-text uppercase">Hallo {{ first_name }},</h2>
</template>

<style scoped lang="scss">

</style>