<script setup>

</script>

<template>
  <div id="loadingscreen">
    <div class="dino">
      <img src="@/assets/images/fourplex-small.svg" alt="">
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes rotatefan {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#loadingscreen {
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity .4s;

  .dino {
    width: 5rem;
    height: 5rem;


    img {
      animation: rotatefan 1.5s 0s cubic-bezier(.8,0,.2,1) infinite;
    }
  }
}
</style>