<script setup>
import { computed, ref, watch } from 'vue';
import { faFacebook, faGoogle, faInstagram, faLinkedin, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const expanded = ref(false);

import Storyblok from '@/plugins/storyblok';

let data = await Storyblok.storyblokApiRequest('cdn/datasource_entries', {
  datasource: 'socials',
});

const socials = ref(
    data.datasource_entries.reduce((acc, social) => {
      acc[social.name] = social.value;
      return acc;
    }, {})
);

watch(expanded, (newValue) => {
  const appElement = document.getElementById('app');
  const bodyElement = document.getElementsByTagName('body')[0];
  if (appElement) {
    appElement.style.overflow = newValue ? 'hidden' : 'scroll';
    bodyElement.style.overflow = newValue ? 'hidden' : 'scroll';
  }
});
</script>

<template>
  <header>
    <div class="container boxed">
      <div class="nav flex justify-content-space-between align-items-center">
        <a href="/" class="logo">
          <img src="@/assets/images/fourplex.svg" alt="fourplex | Die Agentur.">
        </a>

        <ul class="nav flex align-items-center list-style-none gap-75">
          <li>
            <a href="/projekte">Projekte</a>
          </li>

          <li>
            <a href="/">Agentur</a>
          </li>

          <li>
            <a href="/jobs">Jobs</a>
          </li>

          <li>
            <a href="/kontakt">Kontakt</a>
          </li>
        </ul>
      </div>

      <div class="mobile-nav full-width flex justify-content-space-between align-items-center">
        <a href="/" class="logo" :class="{ expanded: expanded }">
          <img src="@/assets/images/fourplex.svg" alt="fourplex | Die Agentur." v-if="!expanded">
          <img src="@/assets/images/fourplex-small.svg" alt="fourplex | Die Agentur." class="none" :class="{ expanded: expanded }">
        </a>

        <div class="burger" v-on:click="expanded = !expanded" :class="{ expanded: expanded }">
          <span :class="{ curved: expanded }"></span>
          <span class="drip" :class="{ active: expanded }"></span>
          <span></span>
        </div>

        <div class="mobile-nav-window" :class="{ expanded: expanded }">
          <div class="container boxed full-height flex flex-direction-column justify-content-space-between gap-50">
            <ul class="flex flex-direction-column list-style-none gap-25">
              <li>
                <a href="/projekte">Projekte</a>
              </li>

              <li>
                <a href="/">Agentur</a>
              </li>

              <li>
                <a href="/jobs">Jobs</a>
              </li>

              <li>
                <a href="/kontakt">Kontakt</a>
              </li>
            </ul>

            <div class="group flex flex-direction-column gap-25">
              <div class="info flex flex-direction-column gap-16">
                <a href="/impressum">Impressum</a>
                <a href="/datenschutz">Datenschutz</a>
                <a href="/agb">AGB</a>
              </div>

              <ul class="socials flex align-items-center list-style-none gap-25">
                <li>
                  <a :href="socials.instagram">
                    <FontAwesomeIcon :icon="faInstagram" />
                  </a>
                </li>

                <li>
                  <a :href="socials.facebook">
                    <FontAwesomeIcon :icon="faFacebook" />
                  </a>
                </li>

                <li>
                  <a :href="socials.tiktok">
                    <FontAwesomeIcon :icon="faTiktok" />
                  </a>
                </li>

                <li>
                  <a :href="socials.youtube">
                    <FontAwesomeIcon :icon="faYoutube" />
                  </a>
                </li>

                <li>
                  <a :href="socials.linkedin">
                    <FontAwesomeIcon :icon="faLinkedin" />
                  </a>
                </li>

                <li>
                  <a :href="socials.google">
                    <FontAwesomeIcon :icon="faGoogle" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
header {
  position: absolute;
  z-index: 9997;
  top: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(31, 36, 37, 1) 0%, rgba(31, 36, 37, 0) 100%);

  @media (max-width: #{$media-phone}) {
    .nav {
      display: none !important;
    }

    .mobile-nav {
      display: flex !important;
    }
  }

  .nav {
    display: flex;
  }

  .mobile-nav {
    display: none;
  }

  .logo {
    height: 5rem;

    img {
      height: 100%;

      &.none {
        opacity: 0;
      }

      &.expanded {
        opacity: 1;
      }
    }
  }

  li a {
    list-style: none;
    font-size: $font-normal;
    text-transform: uppercase;
    font-weight: 700;
    color: $text;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0;
      height: 2px;
      width: 0;
      background-color: #ffffff;
      transition: width 0.3s ease-in-out;
    }

    &:hover:after, &.active:after {
      width: 100%;
    }
  }

  .logo {
    position: relative;
    z-index: 9999;

    @media only screen and (max-width: #{$media-phone}) {
      max-width: 40%;
    }
  }

  .burger {
    position: relative;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;

    span {
      display: inline-block;
      height: 0;
      width: 100%;
      border: 2px solid white;
      border-bottom: 0;
      transition: .1s ease-in-out;
      border-radius: 4px;

      &.curved {
        height: 7.5px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }

      &.drip {
        position: relative;
        width: 100%;

        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-radius: 1px;
          transition: .1s border-left-width, .1s border-right-width;
        }

        &.active:after {
          border-left: 2.5px solid transparent;
          border-right: 2.5px solid transparent;
          border-top: 2.5px solid white;
        }
      }
    }
  }

  .mobile-nav-window {
    position: fixed;
    z-index: 9998;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background: $secondary;
    opacity: 0;
    transition: height .2s, width .2s, opacity .1s;

    .container {
      padding-top: 35%;

      .info a {
        color: rgba(white, .75);
        font-size: $font-normal;
        font-weight: 400;
        text-transform: none;
      }

      .socials li a {
        color: $accent;
      }
    }

    &.expanded {
      opacity: 1;
      width: 100%;
      height: 100%;
    }
  }
}
</style>