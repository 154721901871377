<script setup>
import { ref } from 'vue';
import { computed } from 'vue';

import Storyblok from '@/plugins/storyblok';
import ProjectPreview from '@/components/pages/ProjectPreview.vue';

defineProps({ blok: Object, name: String })

const navigateToProject = (slug) => {
  window.location.href = slug;
};

const data = await Storyblok.storyblokApiRequest('cdn/stories', {
  starts_with: 'projekte/'
});

let allProjects = ref(data.stories.filter(item => item.name !== 'Projects'));

const playVideo = (index) => {
  const videoElement = document.querySelectorAll('.video-background')[index];

  if (videoElement) {
    videoElement.play().catch((err) => {
      console.error('Error playing video:', err);
    });
  }
};

const stopVideo = (index) => {
  const videoElement = document.querySelectorAll('.video-background')[index];
  if (videoElement) {
    videoElement.pause();
  }
};

const isMainPage = computed(() => {
  return window.location.pathname === '/projekte';
});
</script>

<template>
  <div v-if="isMainPage">
    <StoryblokComponent
        v-for="inblok in blok.components"
        :blok="inblok"
        :key="inblok._uid"
    />
  </div>

  <section id="projects" v-if="isMainPage">
    <div class="container flex boxed">
      <ul class="full-width grid gap-50 list-style-none">
        <li v-for="(project, i) in allProjects" :key="i" class="tile flex flex-direction-column normal gap-16 justify-content-flex-end" @mouseenter="playVideo(i)" @mouseleave="stopVideo(i)" @click="navigateToProject(project.full_slug)">
          <span class="font-medium weight-bold color-text uppercase">{{ project.name }}</span>
          <span class="font-normal weight-light color-text uppercase">{{ project.content.subheadline }}</span>

          <div class="background">
            <video class="video-background" playsinline muted loop preload="none">
              <source :src="project.content.video.url" type="video/mp4" />
            </video>

            <img :src="project.content.image.filename" alt="" class="image-background" />
          </div>
        </li>
      </ul>
    </div>
  </section>

  <ProjectPreview v-else :blok="blok" :name="name" />
</template>

<style scoped lang="scss">
video {
  pointer-events: none;
}

#projects {
  ul {
    grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: #{$media-phone}) {
      grid-template-columns: 1fr;
    }

    .tile {
      position: relative;
      z-index: 9;
      width: 100%;
      overflow: hidden;
      cursor: pointer;
      aspect-ratio: 16/9;

      span {
        position: relative;
        z-index: 10;
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(31, 36, 37, .85) 0%, rgba(31, 36, 37, 0) 100%);

        .video-background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          object-fit: cover;
          transition: opacity .4s;
          z-index: -1;
        }

        .image-background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 1;
          transition: opacity .4s;
          z-index: -1;
        }
      }

      &:hover .image-background {
        opacity: 0;
      }

      &:hover .video-background {
        opacity: 1;
      }
    }
  }
}
</style>