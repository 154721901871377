<script setup>
import { marked } from 'marked';

const props = defineProps({ blok: Object });

let text = marked.parse(props.blok.text);
</script>

<template>
  <div class="markdown" v-html="text"></div>
</template>

<style lang="scss" scoped>
.markdown {
  h1 {
    font-size: $font-xxl !important;
  }

  h2 {
    font-size: $font-xl !important;
  }

  h3 {
    font-size: $font-large !important;
  }

  h4 {
    font-size: $font-medium !important;
  }

  h5 {
    font-size: $font-normal !important;
  }

  h6 {
    font-size: $font-normal !important;
  }

  p, a {
    font-size: $font-small !important;
  }

  @media (max-width: #{$media-phone}) {
    p, a {
      font-size: 12px;
    }

    h5, h6 {
      font-size: 16px;
    }

    h4 {
      font-size: 20px;
    }

    h3 {
      font-size: 25px;
    }

    h2 {
      font-size: 30px;
    }

    h1 {
      font-size: 50px;
    }
  }
}
</style>
