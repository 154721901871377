<script setup>
import Storyblok from '@/plugins/storyblok';

defineProps({ blok: Object });

let data = await Storyblok.storyblokApiRequest('cdn/stories/home', {
  resolve_relations: 'team.employees'
});

console.log(data.rels)
</script>

<template>
  <div class="team-container grid">
    <div class="member" v-for="(member, i) in data.rels" :key="i">
      <img :src="member.content.image.filename" :alt="member.name">

      <div class="information flex flex-direction-column justify-content-space-between align-items-flex-start">
        <div class="group flex flex-direction-column">
          <span class="font-secondary weight-light font-small color-text">{{ member.content.job }}</span>
          <span class="font-secondary weight-bold font-small color-text">{{ member.content.first_name }} {{ member.content.last_name }}</span>
        </div>

        <span class="font-secondary font-small color-text">{{ member.content.email }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.team-container {
  grid-template-columns: repeat(5, 1fr);
  border-image-width: 0;

  @media only screen and (max-width: #{$media-phone}) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.member {
  position: relative;
  text-align: center;

  .information {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($accent, 0.75);
    opacity: 0;
    transition: .2s ease;
    padding: 25px;
    text-align: left;

    &:hover {
      opacity: 1;
    }

    .group {
      gap: 8px;
    }
  }
}

img {
  width: 100%;
  height: 100%;
}
</style>